<template>
  <div v-loading="loading" class="page-task-manger-list">
    <div class="public-breadcrumb">
      <router-link style="color: #6e6b7b" to="/">{{
        $t("devices.Welcome")
      }}</router-link>
      >
      <span style="color: #3461ff">{{ $t("Task List") }}</span>
    </div>
    <topbar>
      <div class="handleBtn">
        <div>
          <el-button type="danger" size="small" @click="handleBatchDelete">
            <i class="el-icon-delete" />
            {{ $t("button.Batch Delete") }}
          </el-button>
          <!-- :disabled="selectionList.length === 0" -->
        </div>
      </div>
    </topbar>
    <div class="table_set">
      <div
        class="select_filter"
        style="
          margin-bottom: 15px;
          display: flex;
          justify-content: space-between;
        "
      >
        <div>
          <span class="text"
            >{{ $t("taskManagerInfo.Records") }}: {{ pageParams.total }}
          </span>
          <el-select
            v-model="pageParams.storeType"
            placeholder=""
            @change="getScheduleListReq"
            size="small"
          >
            <el-option
              v-for="(item, i) in storeTypeList()"
              :key="i"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </div>
        <button class="btnreo" @click="getScheduleListReq" ref="btn">
          <i class="el-icon-refresh"></i>
        </button>
      </div>
      <div class="table-content">
        <el-table
          :data="tableData"
          style="width: 100%; margin-bottom: 20px"
          :header-cell-style="{ 'text-align': 'center' }"
          row-key="id"
          @sort-change="handleSortChange"
          @selection-change="handleSelectionChange"
        >
          <el-table-column align="center" type="selection">
            <el-table-column
              align="center"
              width="55"
              type="selection"
              style="display: none"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column
            :label="$t('taskManagerInfo.Store Name')"
            prop="storeName"
          >
            <el-table-column prop="storeName" min-width="150" align="center">
              <template slot="header">
                <el-input
                  v-model="pageParams.storeName"
                  size="mini"
                  placeholder=""
                  @input="getScheduleListReq"
                  clearable
                />
              </template>
            </el-table-column>
          </el-table-column>

          <el-table-column
            :label="$t('taskManagerInfo.ESLCode')"
            sortable="custom"
            prop="tagIdentity"
          >
            <el-table-column prop="tagIdentity" min-width="150" align="center">
              <template slot="header">
                <el-input
                  v-model="pageParams.tagIdentity"
                  size="mini"
                  placeholder=""
                  @input="getScheduleListReq"
                  clearable
                />
              </template>
            </el-table-column>
          </el-table-column>

          <el-table-column
            :label="$t('taskManagerInfo.Task Type')"
            sortable="custom"
            prop="taskTypeId"
          >
            <el-table-column prop="taskTypeId" min-width="220" align="center">
              <template slot="header">
                <el-select
                  v-model="pageParams.taskTypeId"
                  class="task_el_date_picker"
                  size="mini"
                  clearable
                  @change="getScheduleListReq"
                >
                  <el-option
                    v-for="dict in taskTypeList"
                    :key="dict.codeId"
                    :label="dict.codeName"
                    :value="dict.codeId"
                  />
                </el-select>
              </template>
              <template slot-scope="scope">
                <span class="table-cell__task-type">
                  <span class="table-cell__task-type-name">
                    {{ scope.row.taskTypeTranslate }}
                  </span>
                </span>
              </template>
            </el-table-column>
          </el-table-column>

          <el-table-column
            :label="$t('taskManagerInfo.Task State')"
            sortable="custom"
            prop="taskStateTranslate"
          >
            <el-table-column
              prop="taskStateTranslate"
              min-width="220"
              align="center"
            >
              <template slot="header">
                <el-select
                  v-model="pageParams.taskState"
                  class="task_el_date_picker"
                  size="mini"
                  clearable
                  @change="getScheduleListReq"
                >
                  <el-option
                    v-for="dict in taskStateList"
                    :key="dict.codeId"
                    :label="dict.codeName"
                    :value="dict.codeId"
                  />
                </el-select>
              </template>
            </el-table-column>
          </el-table-column>

          <el-table-column
            :label="$t('taskManagerInfo.Begin Time')"
            sortable="custom"
            prop="startTime"
          >
            <el-table-column prop="startTime" min-width="220" align="center">
              <template slot="header">
                <el-date-picker
                  v-model="pageParams.startTime"
                  class="task_el_date_picker"
                  size="mini"
                  type="daterange"
                  range-separator="-"
                  @change="getScheduleListReq"
                  placement="bottom-start"
                  clearable
                  value-format="yyyy-MM-dd"
                />
              </template>
            </el-table-column>
          </el-table-column>

          <el-table-column
            :label="$t('taskManagerInfo.End Time')"
            sortable="custom"
            prop="endTime"
          >
            <el-table-column prop="endTime" min-width="220" align="center">
              <template slot="header">
                <el-date-picker
                  v-model="pageParams.endTime"
                  class="task_el_date_picker"
                  size="mini"
                  type="daterange"
                  range-separator="-"
                  @change="getScheduleListReq"
                  placement="bottom-start"
                  clearable
                  value-format="yyyy-MM-dd"
                />
              </template>
            </el-table-column>
          </el-table-column>
          <!-- 结果 -->
          <el-table-column
            :label="$t('taskManagerInfo.Result')"
            sortable="custom"
            prop="result"
          >
            <el-table-column prop="result" width="120px" align="center">
              <template #header>
                <el-input
                  v-model="pageParams.result"
                  size="mini"
                  placeholder=""
                  @input="getScheduleListReq"
                  clearable
                />
              </template>
              <template slot-scope="scope">
                {{ scope.row.taskResult }}
              </template>
            </el-table-column>
          </el-table-column>

          <!-- 基站物理地址 -->
          <el-table-column
            :label="$t('taskManagerInfo.Mac')"
            sortable="custom"
            prop="macAddress"
          >
            <el-table-column prop="macAddress" width="180px" align="center">
              <template #header>
                <el-input
                  v-model="pageParams.macAddress"
                  size="mini"
                  placeholder=""
                  @input="getScheduleListReq"
                  clearable
                />
              </template>
              <template slot-scope="scope">
                {{ scope.row.macAddress }}
              </template>
            </el-table-column>
          </el-table-column>

          <el-table-column
            :label="$t('taskManagerInfo.Action')"
            fixed="right"
            width="120"
          >
            <el-table-column width="120">
              <template slot-scope="{ row }">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <span
                    class="table-icon icon"
                    @click="handleDeleteTask(row)"
                    :title="$t('button.Delete')"
                  >
                    <i
                      class="el-icon-delete"
                      style="
                        width: 20px;
                        height: auto;
                        margin-right: 5px;
                        cursor: pointer;
                      "
                    ></i>
                  </span>
                </div>
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>
      </div>
      <pagination :tableInfo="pageParams" @changePage="changePage" />
    </div>

    <!-- 确认弹窗 -->
    <confirm-dialog
      :dialogVisible="isVisibleConfirmDialog"
      @handleClose="handelConfirmDialog"
      :confirmObj="confirmObj"
    >
    </confirm-dialog>
  </div>
</template>

<script>
import {
  getTaskList,
  deleteTask,
  batchDeleteTask,
  getCodeList,
} from "@/libs/api/taskManger.js";
import topbar from "@/components/ui-topbar/index.vue";
import modelUi from "./utils/modelUi.js";
import { parseResp, parseError } from "./utils/requestUtil.js";
import { getSelectedStoreId } from "./utils/cacheUtil.js";
import { commonStore } from "@/store/global-select-store";
import pagination from "@/components/pagination";
import ConfirmDialog from "@/components/confirm-dialog/index.vue";
import { storeTypeList } from "@/libs/enum";
export default {
  components: {
    topbar,
    pagination,
    ConfirmDialog,
  },
  data() {
    return {
      storeTypeList,
      commonStore,
      loading: false,
      selectionList: [],
      pageParams: {
        current: 1,
        size: 10,
        storeName: "",
        storeId: getSelectedStoreId(),
        storeType: 2,
        tagIdentity: "",
        taskTypeId: "",
        taskState: "",
        startTime: null,
        endTime: null,
        total: 0,
        result: "",
        macAddress: "",
      },
      tableData: [],
      selectIds: [],
      columns: [],
      taskTypeList: [],
      taskStateList: [
        {
          codeId: 1101,
          codeName: this.$t("taskManagerInfo.taskStateList.one"),
        },
        {
          codeId: 1102,
          codeName: this.$t("taskManagerInfo.taskStateList.two"),
        },
        {
          codeId: 1103,
          codeName: this.$t("taskManagerInfo.taskStateList.three"),
        },
      ],
      isVisibleConfirmDialog: false,
      confirmObj: {
        type: this.$t("taskManagerInfo.Tips"),
        cancelText: this.$t("taskManagerInfo.Cancel"),
        confirmText: this.$t("taskManagerInfo.Sure"),
        content: this.$t(
          "taskManagerInfo.Are you sure to delete the selected task？"
        ),
      },
      currentConfirmType: "",
      currentTid: {},
    };
  },
  created() {
    commonStore.setInjectCallBack(this.getScheduleListReq);
    this.getScheduleListReq();
    this.getTaskTypeListReq();
  },
  methods: {
    // 获取任务类型--下拉选项内容
    getTaskTypeListReq() {
      getCodeList(12)
        .then((res) => {
          const result = parseResp(res);
          if (result.success) {
            let typeList = result.data;
            // typeList = typeList.filter(
            //   (item) => item.codeHelp === "taskSchedule"
            // );
            console.log("typeList", typeList);
            this.taskTypeList = typeList;
          } else {
            modelUi.msgError(result.message);
          }
        })
        .catch((err) => {
          modelUi.msgError(parseError(err));
        });
    },
    changePage() {
      this.getScheduleListReq();
    },
    //  批量删除任务
    handleBatchDelete() {
      const { selectionList } = this;
      if (!selectionList.length) {
        modelUi.msgError(this.$t("taskManagerInfo.No data selected"));
        return;
      }
      this.isVisibleConfirmDialog = true;
      this.currentConfirmType = "batchDeleteTask";
      this.confirmObj.content = this.$t(
        "taskManagerInfo.Are you sure to delete the selected task？"
      );

      console.log(selectionList)
      const tids = selectionList.map(item => {
        return item.tid
      })

      console.log(tids)
      this.currentTid = {
        tids: tids.join(',')
      }

      console.log(this.currentTid)
    },

    handleSelectionChange(selection) {
      this.selectionList = selection;
    },

    handleSortChange(v1) {

      console.log(v1)

      const { prop } = v1;
      const { order } = v1;
      let orderStr = "";
      if (order === "descending") {
        orderStr = "desc";
      }
      if (order === "ascending") {
        orderStr = "asc";
      }

      let keyName = "";
      if (prop === "tagIdentity") {
        keyName = "tag_identity";
      }
      if (prop === "taskTypeId") {
        keyName = "task_type";
      }
      if (prop === "taskStateTranslate") {
        keyName = "task_state";
      }
      if (prop === "startTime") {
        keyName = "start_time";
      }
      if (prop === "endTime") {
        keyName = "end_time";
      }
      if(prop === "result"){
        keyName = "task_result";
      }
      if(prop === "macAddress"){
        keyName = "mac_address";
      }

      if (orderStr) {
        const orders = [];
        const orderItem = `${orderStr}:${keyName}`;
        orders.push(orderItem);
        this.pageParams.orders = orders;
      } else {
        this.pageParams.orders = [];
      }
      this.getScheduleListReq();
    },

    // 获取任务列表
    getScheduleListReq() {
      const storeId = getSelectedStoreId();
      const { storeType } = this.pageParams;
      const { taskTypeId } = this.pageParams;
      const { tagIdentity } = this.pageParams;
      const { startTime } = this.pageParams;
      const { storeName } = this.pageParams;
      const { taskState, result, macAddress } = this.pageParams;

      const { endTime } = this.pageParams;
      const { current } = this.pageParams;
      const { size } = this.pageParams;
      const { orders } = this.pageParams;

      let startDate = ""
      if(startTime&&startTime != ""){
        startDate = startTime.join(',')
      }

      let endDate = ""
      if(endTime&&endTime != ""){
        endDate = endTime.join(',')
      }

      getTaskList({
        storeId,
        storeType,
        storeName,
        taskTypeId,
        tagIdentity,
        taskState,
        startDate,
        endDate,
        result,
        macAddress,
        current,
        size,
        orders,
      })
        .then((res) => {
          const result = parseResp(res);
          if (result.success) {
            this.tableData = result.data.records || [];
            this.pageParams.current = result.data.current;
            this.pageParams.total = result.data.total;
          } else {
            modelUi.msgError(result.message);
          }
        })
        .catch((err) => {
          modelUi.msgError(parseError(err));
        });
    },

    // 删除单个任务
    handleDeleteTask(row) {
      console.log(row);
      this.currentTid = {
        tid: row.tid,
      };
      this.isVisibleConfirmDialog = true;
      this.currentConfirmType = "deleteTask";
      this.confirmObj.content = this.$t(
        "taskManagerInfo.Are you sure to delete the selected task？"
      );
    },

    handelConfirmDialog(val) {
      const { currentConfirmType } = this;
      if (val === "confirm") {
        if (currentConfirmType === "batchDeleteTask") {
          batchDeleteTask(this.currentTid).then((res) => {
            console.log(res);
            this.loading = true;
            if (res.data.state == true) {
              this.$message.success(
                this.$t("taskManagerInfo.Operation success")
              );
              this.isVisibleConfirmDialog = false;
              this.getScheduleListReq();
            } else {
              this.$message.error(res.data.message);
            }
            this.loading = false;
          });
        }

        if (currentConfirmType === "deleteTask") {
          deleteTask(this.currentTid).then((res) => {
            console.log(res);
            this.loading = true;
            if (res.data.state == true) {
              this.$message.success(
                this.$t("taskManagerInfo.Operation success")
              );
              this.isVisibleConfirmDialog = false;
              this.getScheduleListReq();
            } else {
              this.$message.error(res.data.message);
            }
            this.loading = false;
          });
        }
      } else {
        this.isVisibleConfirmDialog = false;
      }
    },

  },
  computed: {
    language() {
      return this.$store.state.appConfig.language;
    },
  },
  watch: {
    language(val) {
      if (val) this.getScheduleListReq();
    },
  },
};
</script>
<style lang="scss" scoped>
.btnreo {
  background: #35b871;
  border: none;
  padding: 6px 8px;
  border-radius: 4px;
  color: white;
}
.handleBtn {
  display: flex;
  justify-content: space-between;
  .el-button {
    border: none;
  }
}
::v-deep .hideClass {
  visibility: hidden;
}
.icon {
  padding: 6px;
  cursor: pointer;
  .svg-icon {
    width: 23px;
    height: 23px;
  }
}

.text {
  padding: 0 10px 0 15px;
}
.table_set {
  padding: 15px 0;
  background-color: white;
  .select_filter {
    padding-right: 15px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    .table_label_size {
      width: 160px;
      font-size: 16px;
    }
    .table_select {
      width: 214px;
    }
  }
  .icon {
    padding: 6px;
    cursor: pointer;
    .svg-icon {
      width: 23px;
      height: 23px;
    }
  }
  .b_pag {
    padding: 0 35px;
  }
  .tb_checkbox {
    vertical-align: middle;
  }
}
.table-content ::v-deep {
  .el-table
    .el-table__header-wrapper
    .el-table__header
    .is-group
    tr:nth-of-type(2)
    th:nth-of-type(1)
    .cell {
    display: none;
  }
}
.table-set::-webkit-scrollbar {
  width: 1600px;
}

.page-task-manger-list {
  // .task_el_input {
  // }

  .task_el_date_picker {
    width: 200px;
  }

  .table-cell__task-type {
    display: inline-block;
    background-color: #f1f1f1;
    border-radius: 14px;
    padding: 2px 10px;
  }

  .table-cell__task-type-icon {
    width: 16px;
    height: 16px;
  }

  .table-cell__task-type-name {
    color: #555555;
    font-size: 14px;
    margin-left: 5px;
  }

  .table-icon {
    padding: 6px;
    cursor: pointer;
    font-size: 18px;
    color: #000;
  }
}
</style>
